const SESSION_PREFIX = "iris-";

export const SEARCH_INPUT = `${SESSION_PREFIX}-data-grid-search-input`;

export const OPEN_SEARCH_CACHE = 2000;
export const ASSESSMENTS_OPEN_SEARCH_CACHE = 5000;

export const THIRTY_SECONDS = 30000;
export const ONE_MINUTE = 60000;
export const TWO_MINUTES = 120000;
export const FIVE_MINUTES = 300000;
export const THIRTY_MINUTES = 1800000;
